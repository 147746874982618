<template>
    <div class="overflow-x-hidden">
<!-- hero section -->

    <div class="hero-section w-full relative overflow-hidden bg-no-repeat bg-cover bg-center" :style="{'background-image':'url(Images/ChatterrCover.webp)'}">
            <div class="w-full h-full bg-slate-500 top-0 left-0 absolute opacity-30"></div>
    </div>


<!-- Introduction section -->

    <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 mt-24">
        <div class="flex-col md:w-8/12">
            <h3 class="text-sky-500 font-serif font-bold">
                Chatterr
            </h3>
            <h2 class="subheader-text font-extrabold font-serif pb-2 text-slate-700 text-2xl">
                Driving Positive Reviews and Mitigating Negative Feedback
            </h2>
            <p class="text-slate-700 ">
                Chatterr, like Odyssey, is an internal marketing product built specifically for Lithia & Driveway. Chatterr is a reputation management product that mitigates bad reviews for our automotive retailers.
            </p>
        </div>
        <div class="flex-col md:w-4/12 md:pl-12 mt-6 md:mt-0">
            <h3 class="text-sky-500 font-serif font-bold">
                My Role
            </h3>
            <p class="text-slate-700 pb-2">
                I lead the creative and technical build for tools supporting the Chatterr product.
            </p>  
            <p class="text-slate-700">
                Checkout the product website: <a class="text-sky-500 hover:text-sky-700" href="https://www.chatterrep.com" target="_blank">www.chatterrep.com</a>
            </p> 
        </div>
    </div>


<!-- Challenge section -->

    <div class="section w-screen bg-slate-100 py-24 mt-24">
        <div class="flex flex-wrap mx-auto w-10/12 lg:w-7/12">
            <div class="flex-col md:w-6/12 md:pr-12">
                <h3 class="text-sky-500 font-serif font-bold">
                    The Challenge
                </h3>
                <p class="text-slate-700">
                    Chatterr's main product was built around the customer after they completed a transaction. This method unfortunately misses a unique audience that can leave negative reviews: people who interact with the store, but don't make a transaction. 
                </p>
            </div>
            <div class="flex-col md:w-6/12 md:pl-12 mt-6 md:mt-0">
                <h3 class="text-sky-500 font-serif font-bold">
                    The Goal
                </h3>
                <p class="text-slate-700">
                    Create a product extension to mitigate negative reviews from a potential customer who interacts with the store, but doesn't transact. 
                </p>
            </div>
        </div>
    </div>

<!-- Image & text section -->

        <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col justify-center md:w-4/12 md:pr-12 ">
                <h3 class="text-sky-500 font-serif font-bold">
                    User Flow
                </h3>
                <p class="text-slate-700">
                    The potential customer has a bad experience in the store, the employee can navigate them to a QR code at the counter engaging the Chatterr experience.
                </p>
            </div>
            <div class="relative flex-row md:flex-col md:w-8/12 md:pl-12 mt-6 md:mt-0 pb-6 md:pb-0 order-1 md:order-2">
                        <img class="hover:scale-150 transition-all" src="/Images/ChatterrUserFlow.webp">
            </div>
        </div>

<!-- text center section -->

        <div class="section flex flex-wrap justify-center mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col md:text-center justify-center md:w-8/12 md:pr-12 ">
                <h3 class="text-sky-500 font-serif font-bold">
                    Allowing Positive External Reviews
                </h3>
                <p class="text-slate-700">
                    If a customer had a positive experience at the store and wants to set an external review we want a seamless way for them to do so.
                </p>
            </div>
        </div>

<!-- 1 Image -->

        <div class="section flex mx-auto w-12/12 lg:w-7/12 mt-12">
            <div class="flex-col mt-6 md:mt-0">
                <img src="/Images/ChatterrQR_2.gif">
            </div>
        </div>


<!-- text center section -->

        <div class="section flex flex-wrap justify-center mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col md:text-center justify-center md:w-8/12 md:pr-12 ">
                <h3 class="text-sky-500 font-serif font-bold">
                    Mitigating Negative External Reviews
                </h3>
                <p class="text-slate-700">
                    Submitting a form that gets logged and communicated with store leadership if the customer says they were unhappy with their experience. This allows the store leadership to resolve the customer's experience and mitigate a bad review from being posted on an external review site.
                </p>
            </div>
        </div>

<!-- 1 Image -->

        <div class="section flex mx-auto w-12/12 lg:w-7/12 mt-12">
            <div class="flex-col mt-6 md:mt-0">
                <img src="/Images/ChatterrQR_3.gif">
            </div>
        </div>


<!-- Image & text section -->

        <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col justify-center md:w-4/12 md:pr-12 ">
                <h3 class="text-sky-500 font-serif font-bold">
                    Scaling the Experience
                </h3>
                <p class="text-slate-700">
                    Like many of my other projects, this product extension needed to scale across the nation to our nearly 300 rooftops. To do so I utilized our store database and pulled in store information, styles, and content dynamically.
                </p>
            </div>
            <div class="relative flex-row md:flex-col md:w-8/12 md:pl-12 mt-6 md:mt-0 pb-6 md:pb-0 order-1 md:order-2">
                        <img src="/Images/ChatterrQR_1.gif">
            </div>
        </div>


<!-- 3 Image -->

        <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 my-24">
            <div class="flex-col md:w-4/12 md:pr-12 mt-6 md:mt-0">

                <div class="relative flex flex-row sm:flex-col justify-center sm:basis-6/12 ml-auto">
                        <img src="/Images/ToyotaofJackson_Chatterr.webp">
                </div>

            </div>
            <div class="flex-col md:w-4/12 md:pl-6 md:pr-6 mt-6 md:mt-0">

                <div class="relative flex flex-row sm:flex-col justify-center sm:basis-6/12 ml-auto">
                        <img src="/Images/BMWofSeattle_Chatterr.webp">
                </div>

            </div>
            <div class="flex-col md:w-4/12 md:pl-12 mt-6 md:mt-0">

                <div class="relative flex flex-row sm:flex-col justify-center sm:basis-6/12 ml-auto">
                        <img src="/Images/ChevroletofBend_Chatterr.webp">
                </div>

            </div>
        </div>

    </div>

</template>

<script>
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
    name: 'Chatterr',
    mounted() {

    gsap.from(['.hero-section'], {
        delay: 0,
        duration: 1,
        opacity: 0
      });

    let fadein = gsap.utils.toArray('.section')
    fadein.forEach((item) => {

    let tl = gsap.timeline({
      scrollTrigger: {
      trigger: item,
      start:"top 80%"
      }
    });
    tl.from(item, {
      opacity: 0,
      duration: 1
      });
    });

    }

}
</script>

<style scoped>

    .hero-section {
        height: 65vh;
    }

</style>