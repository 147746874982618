<template>

    <div :id="id" class="project group flex flex-col sm:flex-row mb-24">
        <div class="flex flex-row sm:flex-col flex-wrap justify-start sm:justify-center sm:basis-5/12 order-2 sm:order-1 pt-7 sm:pt-0">
            <router-link :to="link">
            <div class="flex-row mb-2 group-hover:text-sky-500 transition-all text-slate-700">
                {{ cta }} <font-awesome-icon icon="fas fa-arrow-right" />
            </div>
            <div class="flex-row mb-2">
                <h2 class="font-extrabold font-serif pb-2 text-slate-700 text-2xl">
                    {{ title }}
                </h2>
            </div>
            <div class="flex-row pb-1">
                <Tag :tags="tags" />
            </div>
            </router-link>
        </div>

        <div class="relative flex flex-row sm:flex-col justify-center sm:basis-6/12 ml-auto order-1 sm:order-2">
            <router-link :to="link">
                <div class="overlay bg-slate-500 top-0 left-0 absolute opacity-30 group-hover:opacity-0 transition-all"></div>
                <img :src="img" />
            </router-link>
        </div>
    </div>
</template>

<script>
import Tag from './Tag.vue'

    export default {
        name: 'ProjectTiles',
        props: {
            id: String,
            title: String,
            img: String,
            link: String,
            cta: String,
            tags: Array
        },
        components: {
            Tag
        }
    }
</script>

<style scoped>

    img, .overlay {
        width: 100%;
        height: 55vh;
        min-height: 300px;
        -o-object-fit: cover;
        object-fit: cover;
    }

</style>