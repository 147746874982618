import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import LADWeb2 from '../views/LADWeb2.vue'
import Odyssey from '../views/Odyssey.vue'
import GreenCars from '../views/GreenCars.vue'
import Chatterr from '../views/Chatterr.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: "MaMoCreative | Portfolio | Marshall Moses"
    }
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta: {
      title: "MaMoCreative | About | Marshall Moses"
    }
  },
  {
    path: '/LADWeb2',
    name: 'LADWeb2',
    component: LADWeb2,
    meta: {
      title: "MaMoCreative | Lithia & Driveway | Marshall Moses"
    }
  },
  {
    path: '/Odyssey',
    name: 'Odyssey',
    component: Odyssey,
    meta: {
      title: "MaMoCreative | Odyssey | Marshall Moses"
    }
  },
  {
    path: '/GreenCars',
    name: 'GreenCars',
    component: GreenCars,
    meta: {
      title: "MaMoCreative | GreenCars | Marshall Moses"
    }
  },
  {
    path: '/Chatterr',
    name: 'Chatterr',
    component: Chatterr,
    meta: {
      title: "MaMoCreative | Chatterr | Marshall Moses"
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
  routes
})

// set each page's title
router.afterEach(to => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
});

export default router
