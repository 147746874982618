<template>
  <div id="particles-js" class="z-0"></div>
  <div class="max-w-7xl m-auto">

    <!-- Hero Section -->

    <div class="flex flex-col justify-center z-10 relative">
      <div class="md:mt-48 mt-32 mx-7 md:ml-48 flex w-auto md:w-2/3">
        <h1 class="header-text text-sky-500 font-serif font-extrabold text-4xl md:text-5xl md:leading-tight">
          Hey, I'm Marshall Moses. <br>
          I'm a Creative Technologist.
          </h1>
      </div>
      <div class="subhead-section mx-7 md:ml-80 flex mt-32 md:mt-36 flex-col w-auto md:w-7/12">
        <h2 class="subheader-text font-extrabold font-serif pb-2 text-slate-700 text-2xl">
          I build <span class="text-sky-500">experiences</span> - from ideation to production. I bridge the gap between <span class="text-sky-500">designers and developers.</span> Technology is always advancing and I'm <span class="text-sky-500">always learning.</span>
        </h2>
        <p class="link-text text-slate-700">
          <router-link to="/about">
            More about me <font-awesome-icon icon="fas fa-arrow-right" />
          </router-link>
        </p>
      </div>
    </div>

      <!-- Project Section -->

    <div class="project-section flex flex-col z-10 relative">
        <div class="mt-32 md:mt-48 mx-7 flex w-auto md:w-2/3">
          <h1 class="project-text text-sky-500 font-serif font-extrabold text-5xl md:leading-tight">
            A few projects...
          </h1>
      </div>
      <div class="projects mx-7 mt-28">
        <ProjectTiles v-for="project in projects"
            ref="project"
            :id="project.id"
            :key="project.title"
            :title="project.title"
            :img="project.img"
            :link="project.link"
            :cta="project.cta"
            :tags="project.tags"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import gsap from 'gsap';
  import ScrollTrigger from "gsap/ScrollTrigger";
  import "particles.js/particles";
  import ProjectTiles from '@/components/ProjectTiles.vue'
  import contents from "../projectContents.json";

  gsap.registerPlugin(ScrollTrigger);

const particlesJS = window.particlesJS;

var hasPlayed = sessionStorage.getItem("firstPlay");

export default {
  name: 'Home',
  components: {
    ProjectTiles
  },
  data() {
    return {
      projects: contents["projects"],
    };
  },
  mounted() {
    particlesJS.load("particles-js", "particlesjs-config.json");
    
    gsap.from('#particles-js', {
      duration: 1,
      opacity: 0,
    });

    if (!hasPlayed) {

      gsap.from(['.header-text','.subhead-section', '.project-text'], {
        stagger: 1.5,
        delay: 0,
        duration: 1.5,
        opacity: 0,
        onComplete: function() {
          sessionStorage.setItem("firstPlay", true);
        }
      });

    }

    if (hasPlayed) {

      gsap.from(['.header-text','.subhead-section', '.project-text'], {
        delay: 0,
        duration: 1,
        opacity: 0
      });

    }

    let fadein = gsap.utils.toArray('.project')
    fadein.forEach((item) => {

    let tl = gsap.timeline({
      scrollTrigger: {
      trigger: item,
      start:"top 75%"
      }
    });
    tl.from(item, {
      opacity: 0,
      duration: 1,
      x: -200
      });
    });

    }

}


</script>

<style>
#particles-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  z-index: 1;
}
</style>