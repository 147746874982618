<template>
        <div class="overflow-x-hidden">
    <!-- hero section -->

        <div class="hero-section w-full relative overflow-hidden bg-no-repeat bg-cover bg-top" :style="{'background-image':'url(Images/OdysseyCover.webp)'}">
                <div class="w-full h-full bg-slate-500 top-0 left-0 absolute opacity-30"></div>
        </div>


    <!-- Introduction section -->

        <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex-col md:w-8/12">
                <h3 class="text-sky-500 font-serif font-bold">
                    Odyssey
                </h3>
                <h2 class="subheader-text font-extrabold font-serif pb-2 text-slate-700 text-2xl">
                    Data-Driven Customer Lifecycle Experiences
                </h2>
                <p class="text-slate-700 ">
                    Odyssey is an internal marketing product built for Lithia & Driveway. Lithia & Driveway have nearly 300 automotive retail locations with 40 Manufacturers supported, and multiple e-commerce platforms. This requires solutions at scale.
                </p>
            </div>
            <div class="flex-col md:w-4/12 md:pl-12 mt-3 md:mt-0">
                <h3 class="text-sky-500 font-serif font-bold">
                    My Role
                </h3>
                <p class="text-slate-700 pb-2">
                    As the creative & technical lead for this product I headed the efforts behind concept development and managing data matrixes that fueled the experiences. 
                </p>  
                <p class="text-slate-700">
                    Checkout the product website: <a class="text-sky-500 hover:text-sky-700" href="https://odyssey.betwixtagency.com" target="_blank">odyssey.betwixtagency.com</a>
                </p> 
            </div>
        </div>


    <!-- Challenge section -->

        <div class="section w-screen bg-slate-100 py-24 mt-24">
            <div class="flex flex-wrap mx-auto w-10/12 lg:w-7/12">
                <div class="flex-col md:w-6/12 md:pr-12">
                    <h3 class="text-sky-500 font-serif font-bold">
                        The Challenge
                    </h3>
                    <p class="text-slate-700">
                        Growth being on the forefront of importance for Lithia & Driveway the company has allowed each store to maintain a level of autonomy in marketing as well as some other business areas. This had created a bloated external marketing spend, and disjointed customer data records, as well as an inconsistent customer experience.
                    </p>
                </div>
                <div class="flex-col md:w-6/12 md:pl-12 mt-3 md:mt-0">
                    <h3 class="text-sky-500 font-serif font-bold">
                        The Goal
                    </h3>
                    <p class="text-slate-700">
                        Build a marketing product driven by our extensive first-party customer data to engage customers through multi-channel lifecycle journeys. These journeys needed to have on-brand creative for 40 OEMs, wrap OEM communications, and be delivered through multiple channels with a digital first strategy. 
                    </p>
                </div>
            </div>
        </div>

    <!-- text center section -->

        <div class="section flex flex-wrap justify-center mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col md:text-center justify-center md:w-8/12 md:pr-12 ">
                <h3 class="text-sky-500 font-serif font-bold">
                    Strategy & Touch Points
                </h3>
                <p class="text-slate-700">
                    With a digital first strategy we lead the experience with email and SMS marketing, followed by social and online display and furthering the experience with optional printed mailers.
                </p>
            </div>
        </div>

    <!-- Image & text section -->

        <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col justify-start md:w-4/12 md:pr-12 ">
                <h3 class="text-sky-500 font-serif font-bold">
                    Dynamic Email Templates
                </h3>
                <p class="text-slate-700">
                    Each subscriber gets a unique to them email experience. This experience is enabled by databases maintained on the back end. A proprietary system of data matching was created to fuel this experience.
                </p>
            </div>
            <div class="relative flex-row md:flex-col md:w-8/12 md:pl-12 mt-3 md:mt-0 pb-6 md:pb-0 order-1 md:order-2">
                        <img src="/Images/Odyssey/OdysseyEmail_Honda.png">
            </div>
        </div>

    <!-- Image Section -->

        <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col justify-center md:w-4/12 md:pr-12 ">
            </div>
            <div class="relative flex-row md:flex-col md:w-8/12 md:pl-12 mt-3 md:mt-0 pb-6 md:pb-0 order-1 md:order-2">
                        <img src="/Images/Odyssey/OdysseyEmail_Toyota.png">
            </div>
        </div>

    <!-- Image Section -->

        <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col justify-center md:w-4/12 md:pr-12 ">
            </div>
            <div class="relative flex-row md:flex-col md:w-8/12 md:pl-12 mt-3 md:mt-0 pb-6 md:pb-0 order-1 md:order-2">
                        <img src="/Images/Odyssey/OdysseyEmail_Volkswagen.png">
            </div>
        </div>

    <!-- text center section -->

        <div class="section flex flex-wrap justify-center mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col md:text-center justify-center md:w-8/12 md:pr-12 ">
                <h3 class="text-sky-500 font-serif font-bold">
                    Email Templates Behind the Scenes
                </h3>
                <p class="text-slate-700">
                    To accomplish a semi-automated experience, the front end email designs had to be dynamic. Pulling in styles, images, store information, subscriber personalization strings, etc. This flowchart gives you a peak into what it took to accomplish this effort.
                </p>
            </div>
        </div>

    <!-- 1 Image -->

        <div class="section flex mx-auto w-12/12 lg:w-7/12 mt-12">
            <div class="flex-col mt-3 md:mt-0">
                <img src="/Images/OdysseyEmailFlow.webp">
            </div>
        </div>

    <!-- text center section -->

        <div class="section flex flex-wrap justify-center mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col md:text-center justify-center md:w-8/12 md:pr-12 ">
                <h3 class="text-sky-500 font-serif font-bold">
                    Templated Experiences
                </h3>
                <p class="text-slate-700">
                    Creating a templated experience that could be dynamic enough to deliver a high quality customer experience was incredibly challenging. Using design resources was incredibly important to build the scale of the product.
                </p>
            </div>
        </div>

    <!-- Image & text section -->

        <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col justify-center md:w-4/12 md:pr-12 ">
                <h3 class="text-sky-500 font-serif font-bold">
                    Online Display
                </h3>
                <p class="text-slate-700">
                    Online display assets were developed to re-engage audiences on external sites. Using customer data to serve the assets was important to reduce spends.
                </p>
            </div>
            <div class="relative flex-row md:flex-col md:w-8/12 md:pl-12 mt-3 md:mt-0 pb-6 md:pb-0 order-1 md:order-2">
                        <img src="/Images/Odyssey/OnlineDisplay_Honda.jpg">
            </div>
        </div>

        
    <!-- Image Section -->

        <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col justify-center md:w-4/12 md:pr-12 ">
            </div>
            <div class="relative flex-row md:flex-col md:w-8/12 md:pl-12 mt-3 md:mt-0 pb-6 md:pb-0 order-1 md:order-2">
                        <img src="/Images/Odyssey/OnlineDisplay_Subaru.jpg">
            </div>
        </div>

    <!-- Image Section -->

        <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col justify-center md:w-4/12 md:pr-12 ">
            </div>
            <div class="relative flex-row md:flex-col md:w-8/12 md:pl-12 mt-3 md:mt-0 pb-6 md:pb-0 order-1 md:order-2">
                        <img src="/Images/Odyssey/OnlineDisplay_Toyota.jpg">
            </div>
        </div>

    <!-- Image & text section -->

        <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col justify-center md:w-4/12 md:pr-12 ">
                <h3 class="text-sky-500 font-serif font-bold">
                    Social Media
                </h3>
                <p class="text-slate-700">
                    Passing our customer data to match and build social media audiences was also important. Assets were designed to mimic creative across each touch point to build brand image.
                </p>
            </div>
            <div class="relative flex-row md:flex-col md:w-8/12 md:pl-12 mt-3 md:mt-0 pb-6 md:pb-0 order-1 md:order-2">
                        <video class="w w-6/12 mx-auto" controls>
                            <source src="/Images/Odyssey/Social_Honda.mp4" type="video/mp4" />
                        </video>
            </div>
        </div>

    <!-- Image Section -->

        <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col justify-center md:w-4/12 md:pr-12 ">
            </div>
            <div class="relative flex-row md:flex-col md:w-8/12 md:pl-12 mt-3 md:mt-0 pb-6 md:pb-0 order-1 md:order-2">
                        <video class="w w-6/12 mx-auto" controls>
                            <source src="/Images/Odyssey/Social_Subaru.mp4" type="video/mp4" />
                        </video>
            </div>
        </div>

    <!-- Image Section -->

        <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col justify-center md:w-4/12 md:pr-12 ">
            </div>
            <div class="relative flex-row md:flex-col md:w-8/12 md:pl-12 mt-3 md:mt-0 pb-6 md:pb-0 order-1 md:order-2">
                        <video class="w w-6/12 mx-auto" controls>
                            <source src="/Images/Odyssey/Social_Toyota.mp4" type="video/mp4" />
                        </video>
            </div>
        </div>

    <!-- Image & text section -->

        <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col justify-center md:w-4/12 md:pr-12 ">
                <h3 class="text-sky-500 font-serif font-bold">
                    Direct Mail
                </h3>
                <p class="text-slate-700">
                    Our last approach is to deliver subscriber personalized direct mail. Direct mail boasts a 100% open rate, even if a subscriber tosses out the mail immediately we know they have at least seen our brand on the assets.
                </p>
            </div>
            <div class="relative flex-row md:flex-col md:w-8/12 md:pl-12 mt-3 md:mt-0 pb-6 md:pb-0 order-1 md:order-2">
                        <img src="/Images/Odyssey/DirectMail_Honda.jpg">
            </div>
        </div>
    
    <!-- Image Section -->

        <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col justify-center md:w-4/12 md:pr-12 ">
            </div>
            <div class="relative flex-row md:flex-col md:w-8/12 md:pl-12 mt-3 md:mt-0 pb-6 md:pb-0 order-1 md:order-2">
                        <img src="/Images/Odyssey/DirectMail_Subaru.jpg">
            </div>
        </div>

    <!-- Image Section -->

        <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col justify-center md:w-4/12 md:pr-12 ">
            </div>
            <div class="relative flex-row md:flex-col md:w-8/12 md:pl-12 mt-3 md:mt-0 pb-6 md:pb-0 order-1 md:order-2">
                        <img src="/Images/Odyssey/DirectMail_Toyota.jpg">
            </div>
        </div>

    <!-- text center section -->

        <div class="section flex flex-wrap justify-center mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col md:text-center justify-center md:w-8/12 md:pr-12 ">
                <h3 class="text-sky-500 font-serif font-bold">
                    Offer Dashboard
                </h3>
                <p class="text-slate-700">
                    The product offering was always evolving, to show our offers efficiently I built custom dashboards using Server-Side Javascript, Jquery, and bootstrap to build quickly and efficiently.
                </p>
            </div>
        </div>

    <!-- 1 Image -->

        <div class="section flex mx-auto w-12/12 lg:w-7/12 mt-12">
            <div class="flex-col mt-3 md:mt-0">
                <img src="/Images/OfferLibrary.gif">
            </div>
        </div>


    <!-- text center section -->

        <div class="section flex flex-wrap justify-center mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col md:text-center justify-center md:w-8/12 md:pr-12 ">
                <h3 class="text-sky-500 font-serif font-bold">
                    Reporting Dashboard
                </h3>
                <p class="text-slate-700">
                    Utilizing PowerBI I created a custom reporting dashboard for our product.
                </p>
            </div>
        </div>

    <!-- 1 Image -->

        <div class="section flex mx-auto w-12/12 lg:w-7/12 mt-12">
            <div class="flex-col mt-3 md:mt-0">
                <img src="/Images/ReportingDashboard.gif">
            </div>
        </div>


    <!-- text center section -->

        <div class="flex flex-wrap justify-center mx-auto w-10/12 lg:w-7/12 my-24">
            <div class="flex flex-col md:text-center justify-center md:w-8/12 md:pr-12 ">
                <h3 class="text-sky-500 font-serif font-bold">
                    The Transition
                </h3>
                <p class="text-slate-700">
                    Transitioning from an external product to our in-house product required the creation of many, many resources and decks. During this transition I presented our product to upper-management stakeholders, clients, and our internal teams to bring everyone up to speed.
                </p>
            </div>
        </div>


    </div>
</template>

<script>
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
    name: 'Odyssey',
    mounted() {

    let fadein = gsap.utils.toArray('.section')
    fadein.forEach((item) => {

    let tl = gsap.timeline({
      scrollTrigger: {
      trigger: item,
      start:"top 80%",
      }
    });
    tl.from(item, {
      opacity: 0,
      duration: 1
      });
    });

    }
}
</script>

<style scoped>

    .hero-section {
        height: 65vh;
    }

</style>