<template>
  <div class="overflow-x-hidden">
    <div class="max-w-7xl m-auto">
    <!-- about me with image -->

        <div class="flex justify-center my-7 md:my-36">
          <div class="flex flex-col justify-center md:flex-row md:space-x-24 mx-7">
            <div class="flex flex-col justify-center overflow-hidden h-auto md:w-5/12 md:mr-0">
              <img class="object-center" src="/Images/About_Image.webp" />
            </div>
            <div class="flex flex-col w-auto justify-center pt-7 md:pt-0 md:w-5/12 md:ml-0">
                <div>
                  <h1 class="text-sky-500 font-serif font-extrabold text-5xl pb-4">A bit about me.</h1>
                </div>
                <div class="text-slate-700">
                  <p class="pb-4">
                    I'm really good at breaking things to figure out how they work. I am a serial DIYer, almost to a fault. While you're reading this I likely have a to-do list as long as your arm - I like being busy and I enjoy making things work.
                  </p>
                  <p class="pb-4">
                    I started designing in my high school digital media class! After high school I was off to Oregon State University where I studied Graphic Design. I started working for a couple of departments on campus building marketing campaigns.

                  </p>
                  <p class="pb-4">
                    After graduating I moved to my hometown to start a career at Lithia & Driveway as a Brand Designer. At Lithia & Driveway I noticed some antiquated processes with serious potential for improvement. Before I knew it I was managing new processes, building apps, developing experiences, and trying to define my role.

                  </p>
                  <p class="pb-4">
                    I enrolled in a Full-Stack Development bootcamp after realizing my insistent need to solve problems, and finally landed on a job title that suited my skillsets: Creative Technologist. Since then I've been building full-stack tools, redesigning websites, connecting platforms, and dreaming outside of the box.
                  </p>
                </div>
            </div>
          </div>
        </div>
    </div>

    <!-- grid area for online resume -->
    <div class="w-screen bg-slate-100">
      <div class="max-w-7xl mx-auto">
        <div class="flex flex-row flex-wrap mx-7 py-12">
          <div class="sm:basis-1/2 lg:basis-1/4">
            <h2 class="font-extrabold font-serif pb-2 text-sky-500 text-2xl">
              Experience
            </h2>
            <p class="text-slate-700">
              <span class="font-serif font-bold">
                Lithia &amp; Driveway
              </span><br>
                Creative Technologist<br>
                Oct 2021 - Present
            </p>
            <p class="text-slate-700 pt-4">
                Brand Designer<br>
                Aug 2016 - Oct 2021
            </p>
            <p class="text-slate-700 pt-4">
              <span class="font-serif font-bold">
                Oregon State University
              </span><br>
                Graphic Designer<br>
                Dec 2013 - Aug 2016
            </p>
          </div>
          <div class="pt-7 sm:pt-0 sm:basis-1/2 lg:basis-1/4">
            <h2 class="font-extrabold font-serif pb-2 text-sky-500 text-2xl">
              Education
            </h2>
            <p class="text-slate-700">
              <span class="font-serif font-bold">
                Altcademy
              </span><br>
                Full-Stack Web Development<br>
                2021 - 2022
            </p>
            <p class="text-slate-700 pt-4">
              <span class="font-serif font-bold">
                Oregon State University
              </span><br>
                BFA Graphic Design<br>
                Graduated Class of 2016
            </p>
          </div>
          <div class="pt-7 sm:pt-0 sm:basis-1/2 lg:basis-1/4">
            <h2 class="font-extrabold font-serif pb-2 text-sky-500 text-2xl">
              Skills
            </h2>
            <p class="text-slate-700">
              <span class="font-serif font-bold">
                Languages
              </span><br>
                Javascript, HTML, CSS, Python, SSJS, AMPscript, VBA, Whatever comes next!
            </p>
            <p class="text-slate-700 pt-4">
              <span class="font-serif font-bold">
                Frameworks & Libraries
              </span><br>
                Vue, React, NPM, Node, JQuery, Bootstrap, Tailwind CSS, GSAP
            </p>
          </div>
          <div class="pt-4 sm:pt-0 sm:basis-1/2 lg:basis-1/4">
            <p class="text-slate-700 md:pt-10">
              <span class="font-serif font-bold">
                Tools
              </span><br>
                Adobe Creative Cloud, Figma, Salesforce Marketing Cloud, Blender, VS Code, Git, Postman, SoapUI, PowerBI, Webflow
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';

export default {

  mounted() {

    gsap.from(['h1','h2','img','p','.w-screen'], {
      delay: 0,
      duration: 1,
      opacity: 0,
    });
  }

}

</script>

<style>
</style>

