<template>
        <div class="tags flex flex-wrap -ml-1" v-for="category in tags" :key="category.tagColor">
            <div
                class="tag text-xs rounded-full flex flex-wrap px-6 py-2 items-center m-1"
                v-for="word in category.tagValue"
                :key="word"
                :style="{
                'background-color': category.tagColor,
                }"
            >
                {{ word }}
      </div>
    </div>

</template>

<script>
export default {
    name: 'Tag',
    props: {
        tags: Array
    }
}
</script>

<style scoped>



</style>