<template>
    <div class="overflow-x-hidden">
<!-- hero section -->

    <div class="hero-section w-full relative overflow-hidden bg-no-repeat bg-cover bg-center" :style="{'background-image':'url(Images/GreenCarsCover.webp)'}">
            <div class="w-full h-full bg-slate-500 top-0 left-0 absolute opacity-30"></div>
    </div>


<!-- Introduction section -->

    <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 mt-24">
        <div class="flex-col md:w-8/12">
            <h3 class="text-sky-500 font-serif font-bold">
                GreenCars
            </h3>
            <h2 class="subheader-text font-extrabold font-serif pb-2 text-slate-700 text-2xl">
                Tooling the Green future of the Automotive Industry
            </h2>
            <p class="text-slate-700 ">
                The future is green, and Lithia & Driveway's CEO believes in a green future. Greencars.com was born to get the company ahead of the sustainable vehicle market and educate our customers on the benefits of going green.
            </p>
        </div>
        <div class="flex-col md:w-4/12 md:pl-12 mt-6 md:mt-0">
            <h3 class="text-sky-500 font-serif font-bold">
                My Role
            </h3>
            <p class="text-slate-700 pb-2">
                I lead concept & development on setting up the Greencars newsletter, from capturing subscribers to automating the email templates for a bi-monthly sent newsletter.
            </p>  
            <p class="text-slate-700">
                Checkout the Greencars website: <a class="text-sky-500 hover:text-sky-700" href="https://greencars.com" target="_blank">greencars.com</a>
            </p> 
        </div>
    </div>


<!-- Challenge section -->

    <div class="section w-screen bg-slate-100 py-24 mt-24">
        <div class="flex flex-wrap mx-auto w-10/12 lg:w-7/12">
            <div class="flex-col md:w-6/12 md:pr-12">
                <h3 class="text-sky-500 font-serif font-bold">
                    The Challenge
                </h3>
                <p class="text-slate-700">
                    Greencars needed a way to communicate both to subscribers of their newsletter, as well as Lithia & Driveway employees. The team for Greencars is small and scrappy, and this solution needed to be automated to limit resources spent. 
                </p>
            </div>
            <div class="flex-col md:w-6/12 md:pl-12 mt-6 md:mt-0">
                <h3 class="text-sky-500 font-serif font-bold">
                    The Goal
                </h3>
                <p class="text-slate-700">
                    Capture subscribers on Greencars.com and send out an automated newsletter from Salesforce Marketing Cloud.
                </p>
            </div>
        </div>
    </div>

<!-- text center section -->

        <div class="section flex flex-wrap justify-center mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col md:text-center justify-center md:w-8/12 md:pr-12 ">
                <h3 class="text-sky-500 font-serif font-bold">
                    Subscriber Form Design
                </h3>
                <p class="text-slate-700">
                    Using Webflow I created a form to capture customer information. I also designed and built success and failure redirects for the full customer experience. 
                </p>
            </div>
        </div>

<!-- 1 Image -->

        <div class="section flex mx-auto w-12/12 lg:w-7/12 mt-24">
            <div class="flex-col mt-6 md:mt-0">
                <img src="/Images/GreenCarsForm.gif">
            </div>
        </div>



<!-- Image & text section -->

        <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col justify-center md:w-4/12 md:pr-12 ">
                <h3 class="text-sky-500 font-serif font-bold">
                    Data Collection and Management
                </h3>
                <p class="text-slate-700">
                    The form uses a POST method to send customer data securely to Salesforce Marketing Cloud. Once the subscriber data is in Marketing Cloud they're assigned a unique identifier and are able to manage preferences in the custom designed management dashboard. 
                </p>
            </div>
            <div class="relative flex-row md:flex-col md:w-8/12 md:pl-12 mt-6 md:mt-0 pb-6 md:pb-0 order-1 md:order-2">
                        <img src="/Images/GreenCarsPreferenceCenter.webp">
            </div>
        </div>

<!-- Image & text section -->

        <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col justify-center md:w-4/12 md:pr-12 ">
                <h3 class="text-sky-500 font-serif font-bold">
                    Automating Newsletter Content
                </h3>
                <p class="text-slate-700">
                    To automate the newsletter I had an RSS feed of the blog content on Greencars.com setup. In Salesforce Marketing Cloud I am able to call the XML file and pull in the most recent content dynamically. 
                </p>
            </div>
            <div class="relative flex-row md:flex-col md:w-8/12 md:pl-12 mt-6 md:mt-0 pb-6 md:pb-0 order-1 md:order-2">
                        <img src="/Images/GreenCarsXML.webp">
            </div>
        </div>

<!-- Image & text section -->

        <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col justify-center md:w-4/12 md:pr-12 ">
                <h3 class="text-sky-500 font-serif font-bold">
                    Designing the HTML Email
                </h3>
                <p class="text-slate-700">
                    Email HTML is very antiquated and requires a level of testing that is unlike anything else in the modern world of development, but I built it as designed based on the Greencars style guide and delivered to subscribers.
                </p>
            </div>
            <div class="relative flex-row md:flex-col md:w-8/12 md:pl-12 mt-6 md:mt-0 pb-6 md:pb-0 order-1 md:order-2">
                        <img src="/Images/GreenCarsNewsletter.webp">
            </div>
        </div>





    </div>

</template>

<script>
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
    name: 'GreenCars',
    mounted() {

    gsap.from(['.hero-section'], {
        delay: 0,
        duration: 1,
        opacity: 0
      });

    let fadein = gsap.utils.toArray('.section')
    fadein.forEach((item) => {

    let tl = gsap.timeline({
      scrollTrigger: {
      trigger: item,
      start:"top 80%",
      }
    });
    tl.from(item, {
      opacity: 0,
      duration: 1
      });
    });

    }

}
</script>

<style scoped>

    .hero-section {
        height: 65vh;
    }

</style>