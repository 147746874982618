import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import VueGtag from "vue-gtag";


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faLinkedin, faGithub, faArrowRight)


const app = createApp(App);

app.use(router);

app.use(VueGtag, {
  config: { 
    id: "UA-22337675-2",
  },
}, router); // <----- add your router here

app.component('font-awesome-icon', FontAwesomeIcon)

app.mount("#app");


