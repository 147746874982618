<template>
    <div class="overflow-x-hidden">
<!-- hero section -->

    <div class="hero-section w-full relative overflow-hidden bg-no-repeat bg-cover bg-top" :style="{'background-image':'url(Images/LADCover.webp)'}">
            <div class="w-full h-full bg-slate-500 top-0 left-0 absolute opacity-30"></div>
    </div>


<!-- Introduction section -->

    <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 mt-24">
        <div class="flex-col md:w-8/12">
            <h3 class="text-sky-500 font-serif font-bold">
                Lithia & Driveway Web 2.0
            </h3>
            <h2 class="subheader-text font-extrabold font-serif pb-2 text-slate-700 text-2xl">
                Modernizing Lithia & Driveway's Web Presence
            </h2>
            <p class="text-slate-700 ">
                Lithia Motors, Inc. (AKA Lithia & Driveway) is one of the largest providers of personal transportation solutions in the United States and is among the fastest growing companies in the Fortune 500. Recently, the company breached into the world of automotive e-commerce with Driveway.com, and sustainable transportation with Greencars.com.
            </p>
        </div>
        <div class="flex-col md:w-4/12 md:pl-12 mt-6 md:mt-0">
            <h3 class="text-sky-500 font-serif font-bold">
                My Role
            </h3>
            <p class="text-slate-700 pb-2">
                As the creative lead on the project I lead design efforts, managed the development of the sites, and navigated the integration of the Workday API for the careers website and researched middleware platforms.
            </p>  
            <p class="text-slate-700">
                Checkout a couple of the live sites: <a class="text-sky-500 hover:text-sky-700" href="https://responsibility.lithiadriveway.com" target="_blank">responsibility.lithiadriveway.com</a>, <a class="text-sky-500 hover:text-sky-700" href="https://investors.lithiadriveway.com" target="_blank">investors.lithiadriveway.com</a>
            </p> 
        </div>
    </div>


<!-- Challenge section -->

    <div class="section w-screen bg-slate-100 py-24 mt-24">
        <div class="flex flex-wrap mx-auto w-10/12 lg:w-7/12">
            <div class="flex-col md:w-6/12 md:pr-12">
                <h3 class="text-sky-500 font-serif font-bold">
                    The Challenge
                </h3>
                <p class="text-slate-700">
                    Lithia & Driveway has an aggressive growth strategy. With the rapid growth Lithia & Driveway had to be scrappy and scale their web presence. Websites were created with little planning and usability suffered because of that.
                </p>
            </div>
            <div class="flex-col md:w-6/12 md:pl-12 mt-6 md:mt-0">
                <h3 class="text-sky-500 font-serif font-bold">
                    The Goal
                </h3>
                <p class="text-slate-700">
                    Our ultimate goal was to improve the user experience for Lithia & Driveway's entire web ecosystem. To do that we had to rope in and consolidate rogue websites, build systems to maintain content and boost SEO, as well as create a consistent brand image.
                </p>
            </div>
        </div>
    </div>

<!-- text center section -->

        <div class="section flex flex-wrap justify-center mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col md:text-center justify-center md:w-8/12 md:pr-12">
                <h3 class="text-sky-500 font-serif font-bold">
                    Brand Consistency
                </h3>
                <p class="text-slate-700">
                    Creating a consistent brand image across the site was imperative to our goal. By creating a system in Figma we could easily collaborate and maintain a consistent style easily.
                </p>
            </div>
        </div>

<!-- 1 Image -->

        <div class="section flex mx-auto w-12/12 lg:w-7/12 mt-12">
            <div class="flex-col mt-6 md:mt-0">
                <img src="/Images/careersRecording.gif">
            </div>
        </div>

<!-- 2 Image -->

        <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 my-12">
            <div class="flex-col md:w-6/12 md:pr-12 mt-6 md:mt-0">

                <div class="relative flex flex-row sm:flex-col justify-center sm:basis-6/12 ml-auto">
                        <img src="/Images/Desktop_2.webp">
                </div>

            </div>
            <div class="flex-col md:w-6/12 md:pl-12 mt-6 md:mt-0">

                <div class="relative flex flex-row sm:flex-col justify-center sm:basis-6/12 ml-auto">
                        <img src="/Images/Desktop_3.webp">
                </div>

            </div>
        </div>

<!-- text center section -->

        <div class="section flex flex-wrap justify-center mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col md:text-center justify-center md:w-8/12 md:pr-12 ">
                <h3 class="text-sky-500 font-serif font-bold">
                    Consolidating the Site Map
                </h3>
                <p class="text-slate-700">
                    Creating as few sites as possible but maintaining our expansive information network was challenging, however, we were able to accomplish our goal.
                </p>
            </div>
        </div>


<!-- 1 Image -->

        <div class="section flex mx-auto w-12/12 lg:w-7/12 mt-12">
            <div class="flex-col mt-6 md:mt-0">
                <img src="/Images/Sitemap.webp">
            </div>
        </div>

<!-- Image & text section -->

        <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex flex-col justify-center md:w-4/12 md:pr-12 ">
                <h3 class="text-sky-500 font-serif font-bold">
                    Understanding The Users
                </h3>
                <p class="text-slate-700">
                    Nailing down the users was a challenge. Our potential audience was incredibly diverse for each site, so with that in mind we had to create an experience that could accommodate with a focus on accessibility.
                </p>
            </div>
            <div class="relative flex-row md:flex-col md:w-8/12 md:pl-12 mt-6 md:mt-0 pb-6 md:pb-0 order-1 md:order-2">
                        <img src="/Images/mobileMockup_2.webp">
            </div>
        </div>

<!-- Image & text section -->

        <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 my-24">
            <div class="flex flex-col justify-center md:w-4/12 md:pr-12 ">
                <h3 class="text-sky-500 font-serif font-bold">
                    Managing Future Content
                </h3>
                <p class="text-slate-700">
                    Maintaining our content and creating a smart Content Management System plan to update sites regularly without creating unsustainable work for the future was necessary. We planned ahead and created modules for each website that could be regularly updated by each department as needed.
                </p>
            </div>
            <div class="relative flex-row md:flex-col md:w-8/12 md:pl-12 mt-6 md:mt-0 pb-6 md:pb-0 order-1 md:order-2">
                        <img src="/Images/Desktop_1.webp">
            </div>
        </div>


<!-- 3 Image

        <div class="section flex flex-wrap mx-auto w-10/12 lg:w-7/12 mt-24">
            <div class="flex-col md:w-4/12 md:pr-12 mt-6 md:mt-0">

                <div class="relative flex flex-row sm:flex-col justify-center sm:basis-6/12 ml-auto">
                        <img src="https://picsum.photos/id/0/1920/1080">
                </div>

            </div>
            <div class="flex-col md:w-4/12 md:pl-6 md:pr-6 mt-6 md:mt-0">

                <div class="relative flex flex-row sm:flex-col justify-center sm:basis-6/12 ml-auto">
                        <img src="https://picsum.photos/id/0/1920/1080">
                </div>

            </div>
            <div class="flex-col md:w-4/12 md:pl-12 mt-6 md:mt-0">

                <div class="relative flex flex-row sm:flex-col justify-center sm:basis-6/12 ml-auto">
                        <img src="https://picsum.photos/id/0/1920/1080">
                </div>

            </div>
        </div>
-->

    </div>

</template>

<script>
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
    name: 'LADWeb2',
    mounted() {

    gsap.from(['.hero-section'], {
        delay: 0,
        duration: 1,
        opacity: 0
      });

    let fadein = gsap.utils.toArray('.section')
    fadein.forEach((item) => {

    let tl = gsap.timeline({
      scrollTrigger: {
      trigger: item,
      start:"top 80%",
      }
    });
    tl.from(item, {
      opacity: 0,
      duration: 1
      });
    });

    }

}
</script>

<style scoped>

    .hero-section {
        height: 65vh;
    }

</style>